/**
 * External Dependencies
 */

import 'jquery';
import Splitting from 'splitting';
import ScrollOut from 'scroll-out';
import "@lottiefiles/lottie-player";
// var Flickity = require('flickity');

var $ = require('jquery');
var jQueryBridget = require('jquery-bridget');
var Flickity = require('flickity');

// make Flickity a jQuery plugin
Flickity.setJQuery( $ );
jQueryBridget( 'flickity', Flickity, $ );

$(document).ready(function() {
  //SPLITTING
  Splitting();

  //SCROLLOUT
  ScrollOut({
   targets: '[data-splitting]',
  });

  // SLIDER
  $('.mobile-slider').each(function() {
    if ($(window).width() < 960) {
      var $carousel = $('.mobile-slider').flickity({
          pageDots: true,
          wrapAround: false,
          cellAlign: 'left',
          draggable: true,
          initialIndex: 0,
          groupCells: false,
          freeScroll: false,
          imagesLoaded: true,
          prevNextButtons: false,
          autoPlay: false,
      });
    }
  });

  $('.slider').each(function() {
    var count = $('.slider > div').length;
    if ($(window).width() < 769) {
      var $carousel = $('.slider').flickity({
          pageDots: true,
          wrapAround: false,
          cellAlign: 'left',
          draggable: true,
          initialIndex: 0,
          freeScroll: false,
          imagesLoaded: true,
          prevNextButtons: false,
          autoPlay: false,
      });
    } else {
      if (count > 3) {
        if ($(window).width() < 769) {
          var $carousel = $('.slider').flickity({
              pageDots: true,
              wrapAround: false,
              cellAlign: 'left',
              draggable: true,
              initialIndex: 0,
              freeScroll: false,
              groupCells: 3,
              imagesLoaded: true,
              prevNextButtons: false,
              autoPlay: false,
          });
        } else {
          var $carousel = $('.slider').flickity({
              pageDots: true,
              wrapAround: false,
              cellAlign: 'left',
              draggable: true,
              initialIndex: 0,
              freeScroll: false,
              groupCells: false,
              imagesLoaded: true,
              prevNextButtons: false,
              autoPlay: false,
          });
        }
      }
    }
  });

  if($('.image-slider').length) {
    var $carousel = $('.image-slider').flickity({
      cellAlign: 'left',
      draggable: true,
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: 6000,
      pageDots: false,
      pauseAutoPlayOnHover: false,
    });

    var flkty = $carousel.data('flickity');
    var $cellButtonGroup = $('.button-group');
    var $cellButtons = $cellButtonGroup.find('.dot');

    $carousel.on( 'select.flickity', function() {
      $cellButtons.filter('.is-selected')
        .removeClass('is-selected');
      $cellButtons.eq( flkty.selectedIndex )
        .addClass('is-selected');
    });

    $cellButtonGroup.on( 'click', '.dot', function() {
      var index = $(this).index();
      $carousel.flickity( 'select', index );
    });

    setTimeout(function() {
      $('.image-slider').flickity('resize');
    }, 250);

    $('.image-slider').on( 'select.flickity', function() {
      $('.image-slider').flickity('playPlayer')
    });
  }

  // SCROLLED
  $(window).on('load scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });

  // MENU
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.close-nav').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  // Language
  $('.language').click(function(e) {
    e.preventDefault(); 
    $(this).toggleClass('active');
    $('body').toggleClass('language-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.close-language').click(function() {
    $('.language').toggleClass('active');
    $('body').toggleClass('language-active');
    $('html').toggleClass('overflow-hidden');
  });

  $(document).keydown(function(event) {
    if (event.keyCode == 27) {
      $(".language").removeClass("active");
      $("body").removeClass("language-active");
      $("body").removeClass("hamburger-active");
      $("html").removeClass("overflow-hidden");
    }
  });

  // SCROLL
  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    $('body,html').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - 50,
    }, 500);
  });

  // News
  $('.news-checkbox').change(handleCatFilter);

  function handleCatFilter () {

    var uristring = '?category=';

    $('.news-checkbox:checked').each(function(index) {
      var isLastElement = index == $('.news-checkbox:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  // Logo Fader
  $(document).ready(function(){

    var max_images    = 4;
    var interval_time = 3000;
  
    var interval      = null;
  
    var active_images = [];
    var hidden_images  = [];
  
  
    setup();
  
    // interval for switching
    interval = setInterval(loop, interval_time);
  
    function setup() {
      // loop through all images and hide the others
      $('#logofader .brand').each(function(index, element) {
        if(index >= max_images) {
          $(this).hide();
        } else {
          $(this).show();
        }
      });
  
    }
  
  
    function loop() {
  
      hidden_images = $('#logofader .brand:hidden').each(function(index2, element1) {
          return element1;
      });
  
      active_images = $('#logofader .brand:visible').each(function(index1, element2) {
          return element2;
      });
  
      var active_random = Math.floor(Math.random() * active_images.length);
      var hidden_random = Math.floor(Math.random() * hidden_images.length);
  
      var rand_image_hidden = $(hidden_images[hidden_random]);
      var rand_image_active = $(active_images[active_random]);
  
      swap(rand_image_hidden, rand_image_active);
  
      // rand_image_hidden.fadeIn();
      // rand_image_active.fadeOut();
  
      rand_image_hidden.show();
      rand_image_active.hide();
  
    }
  
    $( window ).resize(function(e) {
  
      if(this.resizeTO) clearTimeout(this.resizeTO);
  
      this.resizeTO = setTimeout(function() {
  
        if(e.target.innerWidth > 500) {
            max_images = 4;
        } else {
            max_images = 2;
        }
  
        setup();
  
      }, 500);
  
      // clearInterval(interval);
      // interval = null;
      // if(e.target.innerWidth > 500) {
      //     max_images = 4;
      // } else {
      //     max_images = 2;
      // }
      //
      // setup();
    });
  
    window.addEventListener("blur", function(){
      clearInterval(interval);
      interval = null;
    });
  
    window.addEventListener("focus", function(){
      interval = setInterval(loop, interval_time);
    });
  
    function swap(a, b) {
      a = $(a); b = $(b);
      var tmp = $('<span>').hide();
      a.before(tmp);
      b.before(a);
      tmp.replaceWith(b);
    };
  
  });

  // TABNAV
  if($('.js-tabnav-tab').length) {
    (function($) {
      $.fn.tabnav = function tabnav(userConfig) {
        var config = $.extend({
          activeTabClass: 'active',
          activePanelClass: 'active',
        }, userConfig);

        var $wrapper = $(this);
        var $tabs = $wrapper.find('.js-tabnav-tab');
        var $panels = $wrapper.find('.js-tabnav-panel');
        var $panelsWrapper = $wrapper.find('.js-tabnav-panels');
        var $activeTab;
        var $activePanel;
        var activeTab = 0;

        console.log($activeTab);
        console.log(activeTab);

        $panels
        .attr('role', 'tabpanel')
        .attr('aria-hidden', 'true');

        $tabs
        .attr('role', 'tab')
        .attr('aria-selected', 'false')
        .attr('aria-setsize', $tabs.length)
        .attr('tabindex', 0)
        .each(function(i, e) {
          var $elem = $(e);
          if ($elem.hasClass(config.activeTabClass)) {
            setActiveTab(i);
          }
          $elem.attr('aria-posinset', i + 1);
        })
        .click(setTabs)
        .keypress(setTabs);

        function setActiveTab(tabPos) {
          $activeTab = $($tabs.get(tabPos))
          .addClass(config.activeTabClass)
          .attr('aria-selected', 'true');

          $activePanel = $($panels.get(tabPos))
          .addClass(config.activePanelClass)
          .attr('aria-hidden', 'false');

          $panelsWrapper.css('height', $activePanel.height() + 'px');
        }

        function setTabs(e) {
          if (e.type === 'keypress' && e.keyCode !== 13) {
            return;
          }

          var $elem = $(e.currentTarget);

          var tabPos = Number($elem.attr('aria-posinset')) - 1;
          $tabs
          .removeClass(config.activeTabClass)
          .attr('aria-selected', 'false');
          $panels
          .removeClass(config.activePanelClass)
          .attr('aria-hidden', 'true');

          setActiveTab(tabPos);
        }

        $(window).resize(function onWindowResize() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });

        $(window).load(function() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });
      }

      var tabnav = $('.tabnav');
      if (tabnav.length != 0) {
        $(tabnav).each(function(){
          $(this).tabnav();
          console.log('ok')
        });
      }
    }(jQuery));
  }

  // CUSTOM CURSOR
  const cursor = document.querySelector('.cursor');
  const cursorListener = (event) => {
    cursor.style.top = event.y + 'px';
    cursor.style.left = event.x + 'px';
  }

  window.addEventListener('mousemove', cursorListener);

  $('.refresh').mouseover(
    function() {
      $('body').attr('data-cursor', 'refresh');
    }
  ).mouseleave(
    function() {
      $('body').attr('data-cursor', 'normal');
    }
  );

  $('.bg-white, .sub-menu, input, textarea').mouseover(
    function() {
      $('body').attr('data-cursor', 'dark');
    }
  ).mouseleave(
    function() {
      $('body').attr('data-cursor', 'normal');
    }
  );

  $('a, .tabnav__tab, label, button, .close-language, .close-nav').mouseover(
    function() {
      $('body').attr('data-cursor', 'link');
    }
  ).mouseleave(
    function() {
      $('body').attr('data-cursor', 'normal');
    }
  );
});